export interface Route {
	url: string;
	text: string;
	img?: string;
}

//internal routes
export const home: Route = { text: 'Home', url: '/' };
export const products: Route = { text: 'Products', url: '/products/' };
export const photographyPilot: Route = { text: 'Photography pilot', url: '/photography-pilot/' };
export const aboutUs: Route = { text: 'About us', url: '/about-us/' };

// social media
export const discord: Route = {
	text: 'Discord',
	url: 'https://discordapp.com/invite/WHe4EuY',
	img: '/discord.svg'
};
export const twitter: Route = {
	text: 'Twitter',
	url: 'https://twitter.com/kelp_digital',
	img: '/twitter.svg'
};
export const instagram: Route = {
	text: 'Instagram',
	url: 'https://www.instagram.com/kelp.digital/',
	img: '/instagram.svg'
};

export const anagolayUrl = 'https://anagolay.network/';
export const maculaUrl = 'https://macula.link/';
